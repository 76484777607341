<template>
  <div class="create-material">
    <div class="material-title">
      <span class="title-item">Materials：</span>
      <span class="title-item"></span>
      <span class="title-item">Inventory</span>
      <span class="title-item">Delivery quantity</span>
    </div>
    <div v-for="(subItem, subIndex) in visitInfo.materialList" :key="subItem.id + subItem.materielName" class="material-form-item">
      <img v-if="subItem.imgUrl" class="icon-img" :src="subItem.imgUrl.split(',')[0]" alt="" @click.stop="imgPreview([subItem.imgUrl.split(',')[0]])">
      <img v-else class="icon-img" src="@/assets/images/icon_noimg.png" alt="">
      <div class="material-name">
        <span class="txt-label txt-ellipsis">{{ subItem.materielName }}</span>
        <span v-if="subItem.isMain === 1" class="desc-label">{{ subItem.isMain === 1 ? 'Key material' : '' }}</span>
      </div>
      <span class="inventory-label txt-ellipsis">{{ subItem.inventoryCount }}</span>
      <div class="stepper-item">
        <van-stepper :disabled="visitInfo.isEdit == 0" :min="0" :max="subItem.totalInventoryCount" v-model="subItem.materielCount" @plus="changePlusStepper(subItem, 'inventoryCount')" @minus="changeMinusStepper(subItem, 'inventoryCount')" @change="changeStepper(subItem)" />
      </div>
    </div>
    <div v-for="(subItem, subIndex) in visitInfo.protoList" :key="subItem.id" class="sample-form-item">
      <img v-if="subItem.imgUrl" class="form-img" :src="subItem.imgUrl.split(',')[0]" alt="" @click.stop="imgPreview([subItem.imgUrl.split(',')[0]])">
      <img v-else class="form-img" src="@/assets/images/icon_noimg.png" alt="">
      <!-- <div class="sample-right">
        <div class="right-title txt-ellipsis">{{ subItem.productName }}</div>
      </div> -->
      <div class="sample-title">
        <span class="title-item">Sample：</span>
        <span class="title-item"></span>
        <span class="title-item">Inventory</span>
        <span class="title-item">Delivery quantity</span>
      </div>
      <div v-for="(specItem, specIndex) in subItem.specList" :key="specItem.specId" class="form-item">
        <span class="spect-name txt-ellipsis">{{ specItem.specName }}</span>
        <span class="inventory-label txt-ellipsis">{{ specItem.myInventoryCount }}</span>
        <van-stepper :disabled="visitInfo.isEdit == 0" :min="0" :max="specItem.totalMyInventoryCount" v-model="specItem.protoCount" @plus="changePlusStepper(specItem,'myInventoryCount')" @minus="changeMinusStepper(specItem, 'myInventoryCount')" @change="changeProtoStepper(specItem)" />
      </div>
    </div>
    <div class="header-title">Product & Materials：</div>
    <div class="product-img">
      <!-- <img class="icon-img" src="@/assets/images/btn_side_add.png" alt=""> -->
      <van-uploader :disabled="visitInfo.isEdit == 0" v-model="materialFileList2" :before-read="beforeRead" :after-read="(files, detail) => afterRead(files, detail, 'materialFileList')" />
    </div>
  </div>
</template>

<script>
import CreateMixins from './createMixins'
export default {
  name: 'Material',
  mixins: [CreateMixins],
  props: {
    visitInfo: {
      type: Object,
      default: () => {}
    },
    materialFileList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      materialFileList2: this.materialFileList
    }
  },
  methods: {
    changePlusStepper(params, key) {
      console.log('params', params)
      params[key]--
    },
    changeMinusStepper(params, key) {
      // params.inventoryCount++
      params[key]++
    },
    changeStepper(params) {
      console.log('changeStepper', params)
      if (params.materielCount > params.totalInventoryCount) {
        params.inventoryCount = 0
      } else {
        params.inventoryCount = params.totalInventoryCount - params.materielCount
      }
    },
    changeProtoStepper(params) {
      if (params.protoCount > params.totalMyInventoryCount) {
        params.myInventoryCount = 0
      } else {
        params.myInventoryCount = params.totalMyInventoryCount - params.protoCount
      }
    },
  }
}
</script>

<style lang="less" scoped>
.create-material {
  font-size: 12px;
  .material-title,
  .sample-title {
    display: flex;
    border-bottom: 1px solid #dce5f6;
    font-size: 14px;
    font-weight: bold;
    .title-item {
      width: 96px;
    }
    .title-item:nth-child(1) {
      width: 70px;
    }
    .title-item:nth-child(3) {
      width: 80px;
      text-align: center;
    }
    .title-item:nth-child(4) {
      width: 120px;
    }
  }
  .sample-title {
    margin-top: 20px;
  }
  .material-form-item {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid #dce5f6;
    .material-name {
      // flex: 1;
      width: 90px;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .desc-label {
        color: red;
        font-size: 10px;
        margin-top: 5px;
      }
    }
    .inventory-label {
      // flex: 1;
      width: 90px;
      text-align: center;
    }
    .stepper-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-self: flex-end;
    }
    .van-stepper {
      align-self: flex-end;
    }
  }
  .sample-form-item {
    // display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid #dce5f6;
    .sample-right {
      flex: 1;
      margin-left: 10px;
      .right-title {
        width: 280px;
      }
    }
    .form-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;
      border-bottom: 1px solid #dce5f6;
      &:last-child {
        border-bottom: none;
      }
      .spect-name {
        width: 90px;
      }
      .inventory-label {
        width: 90px;
        text-align: center;
      }
    }
  }
  .icon-img {
    width: 50px;
    height: 50px;
  }
  .form-img {
    width: 100%;
    height: 100px;
  }
  /deep/.van-stepper__minus--disabled,
  /deep/.van-stepper__plus--disabled {
    background-color: transparent;
    border: 1px solid #b9cbed;
  }
  /deep/.van-stepper__input {
    background-color: transparent;
    border: 1px solid #b9cbed;
  }
  /deep/.van-stepper__minus,
  /deep/.van-stepper__plus {
    background-color: transparent;
    border: 1px solid #b9cbed;
  }
  .header-title {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 10px 0 10px;
  }
  .product-img {
    display: flex;
    flex-wrap: wrap;
    min-height: 130px;
    padding: 10px;
    margin: 10px 0;
    border-top: 1px solid #dce5f6;
    // border-bottom: 1px solid #dce5f6;
    .icon-img {
      margin-right: 5px;
    }
    /deep/.van-uploader {
      width: 100%;
      padding-top: 5px;
    }
    /deep/.van-uploader__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      // border: 1px solid #007bff;
      border-radius: 10px;
      .van-uploader__upload {
        width: 70px;
        height: 70px;
        margin: 0;
      }
      .van-uploader__upload-icon {
        font-size: 18px;
      }
      .van-uploader__preview {
        width: 70px;
        height: 70px;
        margin: 0 5px 5px 0;
      }
      .van-uploader__file {
        width: 100%;
        height: 100%;
      }
      .van-uploader__preview-image {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
